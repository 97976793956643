import * as duckdb from "@duckdb/duckdb-wasm";
import { useEffect, useState } from "react";
import { Resolvable, ResolvableStatus } from "./resolvable";

const PUBLIC_URL = process.env.PUBLIC_URL;

const DUCKDB_BUNDLES: duckdb.DuckDBBundles = {
  mvp: {
    mainModule: PUBLIC_URL + "/duckdb-mvp.wasm",
    mainWorker: PUBLIC_URL + "/duckdb-browser-mvp.worker.js",
  },
  eh: {
    mainModule: PUBLIC_URL + "/duckdb-eh.wasm",
    mainWorker: PUBLIC_URL + "/duckdb-browser-eh.worker.js",
  },
};
export function useDuckDB() {
  const [state, setState] = useState<Resolvable<duckdb.AsyncDuckDB, unknown>>({
    status: ResolvableStatus.NONE,
  });

  useEffect(() => {
    setState({
      status: ResolvableStatus.RUNNING,
      progress: null,
    });
    (async () => {
      try {
        // Select a bundle based on browser checks
        const bundle = await duckdb.selectBundle(DUCKDB_BUNDLES);
        // Instantiate the asynchronus version of DuckDB-wasm
        const worker = new Worker(bundle.mainWorker!);
        const logger = new duckdb.ConsoleLogger(duckdb.LogLevel.ERROR);
        const db = new duckdb.AsyncDuckDB(logger, worker);
        await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
        setState({
          status: ResolvableStatus.COMPLETED,
          value: db,
        });
      } catch (error) {
        setState({
          status: ResolvableStatus.FAILED,
          error,
        });
      }
    })();
  }, []);

  return state;
}

import { useContext, useEffect, useMemo, useRef } from "react";
import * as d3 from "d3";
import * as colors from "@density/proto-ui-colors";
import { Box } from "@mui/material";
import useSize from "@react-hook/size";

import {
  IndustryContext,
  OrganizationContext,
  PlanContext,
} from "lib/contexts";
import { ColorByOption } from "lib/plan-selection";
import { VisualizationScope } from "components/scope-toggle";
// import { SpacesQueryRow } from "lib/types";

const projection = d3.geoIdentity();
const pathGenerator = d3.geoPath(projection);

const legendHeight = 60;
const legendAxisYOffset = 40;
const legendPadding = {
  left: 20,
  right: 20,
};

export const FloorplanDisplay: React.FunctionComponent<{
  focusedAreaId: string | null;
  onMouseEnterArea: (areaId: string) => void;
  onMouseLeaveArea: (areaId: string) => void;
  onClickArea: (areaId: string) => void;
  onClickBackground: () => void;
  colorBy: ColorByOption;
  visualizationScope: VisualizationScope;
}> = ({
  focusedAreaId,
  onMouseEnterArea,
  onMouseLeaveArea,
  onClickArea,
  onClickBackground,
  colorBy,
  visualizationScope,
}) => {
  const wrapperElementRef = useRef<HTMLElement>(null);
  const legendAxisGroupElementRef = useRef<SVGGElement>(null);
  const [width, height] = useSize(wrapperElementRef);

  const legendWidth = useMemo(() => {
    return Math.min(800, width);
  }, [width]);

  const { plan, floorSpacesResult } = useContext(PlanContext);
  const { portfolioSpacesResult } = useContext(OrganizationContext);
  const { industrySpacesResult } = useContext(IndustryContext);

  const scaleBasis = useMemo(() => {
    const basis =
      visualizationScope === "floor"
        ? floorSpacesResult
        : visualizationScope === "portfolio"
        ? portfolioSpacesResult
        : industrySpacesResult;
    return basis;
  }, [
    floorSpacesResult,
    portfolioSpacesResult,
    industrySpacesResult,
    visualizationScope,
  ]);

  const colorScale = useMemo(() => {
    const scale = d3
      .scaleSequential(d3.interpolateRdYlGn)
      .domain(
        colorBy === "density"
          ? (d3
              .extent(scaleBasis, (d) => d.AVG_TEAM_DENSITY_WHEN_USED)
              .reverse()! as [number, number])
          : [1, 0]
      );
    return scale;
  }, [scaleBasis, colorBy]);

  const legendXScale = useMemo(() => {
    const scale = d3
      .scaleLinear()
      .domain(
        colorBy === "density"
          ? (d3.extent(scaleBasis, (d) => d.AVG_TEAM_DENSITY_WHEN_USED)! as [
              number,
              number
            ])
          : [0, 1]
      )
      .range([legendPadding.left, legendWidth - legendPadding.right])
      .nice();

    return scale;
  }, [scaleBasis, colorBy, legendWidth]);

  const areaBounds = useMemo(() => {
    let minX = Infinity;
    let maxX = 0;
    let minY = Infinity;
    let maxY = 0;
    for (const feature of plan.areaFeatureCollection.features) {
      if (feature.geometry.type === "Polygon") {
        for (const coord of feature.geometry.coordinates[0]) {
          minX = Math.min(minX, coord[0]);
          maxX = Math.max(maxX, coord[0]);
          minY = Math.min(minY, coord[1]);
          maxY = Math.max(maxY, coord[1]);
        }
      }
    }
    return {
      x: minX - 20,
      y: minY - 100,
      width: maxX - minX + 40,
      height: maxY - minY + 200,
    };
  }, [plan.areaFeatureCollection]);

  // Legend Axis
  useEffect(() => {
    const group = legendAxisGroupElementRef.current;
    if (!group) return;
    const axis = d3.axisBottom(legendXScale).ticks(10);
    if (colorBy === "unused-hours") {
      axis.tickFormat(d3.format("~%"));
    }
    const sel = d3.select(group);
    sel.call(axis);
    sel.selectAll("line,path").attr("stroke", colors.Gray300);
    sel
      .selectAll("text")
      .attr("font-family", "Roboto")
      .attr("fill", colors.Gray900);
  }, [legendXScale, colorBy]);

  // const [legendDistributionPlotPlan, legendDistributionPlotComparison] =
  //   useMemo(() => {
  //     // Compute kernel density estimation
  //     const kde = kernelDensityEstimator(
  //       kernelEpanechnikov(2),
  //       legendXScale.ticks(80)
  //     );
  //     const mapper: (row: SpacesQueryRow) => number =
  //       colorBy === "density"
  //         ? (d) => d.AVG_TEAM_DENSITY_WHEN_USED
  //         : (d) => d.UNUSED_HOURS_PERCENT;
  //     const densityPlan = kde(floorSpacesResult.map(mapper));
  //     const densityComparison = kde(scaleBasis.map(mapper));
  //     const yScalePlan = d3
  //       .scaleLinear()
  //       .domain(d3.extent(densityPlan, (d) => d[1]) as [number, number])
  //       .range([legendAxisYOffset, 0]);
  //     const yScaleComparison = d3
  //       .scaleLinear()
  //       .domain(d3.extent(densityComparison, (d) => d[1]) as [number, number])
  //       .range([legendAxisYOffset, 0]);
  //     const areaPlan = d3
  //       .area()
  //       .defined((d) => Boolean(d[0]))
  //       .curve(d3.curveBasis)
  //       .x((d) => legendXScale(d[0]))
  //       .y0(legendAxisYOffset)
  //       .y1((d) => yScalePlan(d[1]));

  //     const areaComparison = d3
  //       .area()
  //       .defined((d) => Boolean(d[0]))
  //       .curve(d3.curveBasis)
  //       .x((d) => legendXScale(d[0]))
  //       .y0(legendAxisYOffset)
  //       .y1((d) => yScaleComparison(d[1]));
  //     return [
  //       areaPlan(densityPlan) || "",
  //       areaComparison(densityComparison) || "",
  //     ];
  //   }, [scaleBasis, floorSpacesResult, colorBy, legendXScale]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "block",
        flex: 1,
        minWidth: 0,
        minHeight: 0,
        boxSizing: "border-box",
      }}
      ref={wrapperElementRef}
      onClick={onClickBackground}
    >
      <svg
        width={width}
        height={Math.max(0, height - legendHeight)}
        // viewBox={`0 0 ${plan.image_width_pixels} ${plan.image_height_pixels}`}
        viewBox={`${areaBounds.x} ${areaBounds.y} ${areaBounds.width} ${areaBounds.height}`}
        style={{
          position: "absolute",
          display: "block",
          width: width,
          height: height - legendHeight,
          maxWidth: "100%",
          maxHeight: `calc(100% - ${legendHeight}px)`,
        }}
      >
        <filter id="grayscale">
          <feColorMatrix type="saturate" values="0" />
        </filter>
        <image opacity={0.8} filter="url(#grayscale)" href={plan.imageUrl} />
        {plan.areaFeatureCollection.features
          .slice()
          .sort((a, b) =>
            d3.descending(a.properties!.area_sqft, b.properties!.area_sqft)
          )
          .map((feature) => {
            if (feature && feature.properties) {
              const isFocused =
                focusedAreaId && focusedAreaId === feature.properties.id;

              const spaceData = floorSpacesResult.find(
                (r) => r.SPACE_ID === feature!.properties!.space_id
              );

              const hasSpaceData = spaceData !== undefined;

              const color = hasSpaceData
                ? colorScale(
                    colorBy === "density"
                      ? spaceData.AVG_TEAM_DENSITY_WHEN_USED!
                      : spaceData.UNUSED_HOURS_PERCENT!
                  )
                : colors.Gray900;

              const areaId = feature.properties.id as string;
              return (
                <path
                  key={areaId}
                  d={pathGenerator(feature)!}
                  fill={color}
                  fillOpacity={hasSpaceData ? (isFocused ? 0.75 : 0.75) : 0}
                  stroke={isFocused ? color : "none"}
                  strokeWidth={"4px"}
                  vectorEffect={"non-scaling-stroke"}
                  strokeLinejoin={"round"}
                  strokeLinecap={"round"}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    onClickArea(areaId);
                  }}
                  onMouseEnter={() => onMouseEnterArea(areaId)}
                  onMouseLeave={() => {
                    onMouseLeaveArea(areaId);
                  }}
                />
              );
            } else {
              return null;
            }
          })}
      </svg>
      <svg
        style={{
          position: "absolute",
          bottom: 0,
          overflow: "visible",
          marginLeft: (width - legendWidth) / 2,
          marginRight: (width - legendWidth) / 2,
        }}
        width={legendWidth}
        height={legendHeight}
        viewBox={`0 0 ${legendWidth} ${legendHeight}`}
      >
        {/* TODO: Show distribution when viewing Density metric */}

        {/* Axis */}
        <g
          transform={`translate(0, ${legendAxisYOffset})`}
          ref={legendAxisGroupElementRef}
        />
        {plan.areaFeatureCollection.features.map((feature) => {
          const isFocused =
            focusedAreaId && focusedAreaId === feature.properties!.id;

          const spaceData = floorSpacesResult.find(
            (r) => r.SPACE_ID === feature!.properties!.space_id
          );

          const hasSpaceData = spaceData !== undefined;

          const color = hasSpaceData
            ? colorScale(
                colorBy === "density"
                  ? spaceData.AVG_TEAM_DENSITY_WHEN_USED!
                  : spaceData.UNUSED_HOURS_PERCENT!
              )
            : colors.Gray900;

          const metricValue =
            colorBy === "density"
              ? spaceData?.AVG_TEAM_DENSITY_WHEN_USED!
              : spaceData?.UNUSED_HOURS_PERCENT!;

          if (!hasSpaceData) return null;
          const areaId = feature.properties!.id as string;
          return (
            <circle
              key={areaId}
              cx={legendXScale(metricValue)}
              cy={26}
              r={isFocused ? 8 : 5}
              fillOpacity={isFocused ? 0.9 : 0.75}
              fill={color}
              onClick={(evt) => {
                evt.stopPropagation();
                onClickArea(areaId);
              }}
              onMouseEnter={() => onMouseEnterArea(areaId)}
              onMouseLeave={() => {
                onMouseLeaveArea(areaId);
              }}
            />
          );
        })}
      </svg>
    </Box>
  );
};

// type KernelFn = (k: number) => number;

// // Function to compute density
// function kernelDensityEstimator(kernel: KernelFn, X: Array<number>) {
//   return function (V: Array<number>) {
//     return X.map(function (x) {
//       return [
//         x,
//         d3.mean(V, function (v) {
//           return kernel(x - v);
//         }),
//       ] as [number, number];
//     });
//   };
// }
// function kernelEpanechnikov(k: number): KernelFn {
//   return function (v: number) {
//     return Math.abs((v /= k)) <= 1 ? (0.75 * (1 - v * v)) / k : 0;
//   };
// }

import * as colors from "@density/proto-ui-colors";
import { Box, IconButton, Tooltip } from "@mui/material";
import * as Icons from "@mui/icons-material";

import { ActiveView } from "lib/types";
import densityMark from "img/density-logo-mark-light.svg";

export const LeftMenuBar: React.FunctionComponent<{
  activeView: ActiveView;
  setActiveView: (view: ActiveView) => void;
}> = ({ activeView, setActiveView }) => {
  const colorLinkActive = colors.Purple300;
  const colorLinkInactive = colors.Gray200;
  return (
    <Box
      sx={{
        width: 40,
        backgroundColor: colors.Gray900,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexShrink: 0,
      }}
    >
      {/* Logo mark */}
      <img style={{ marginTop: 8 }} width={24} src={densityMark} alt="logo" />
      {/* Spacer */}
      <Box sx={{ height: 72 }}></Box>
      {/* Map View */}
      <Tooltip title="Map View" placement="right">
        <IconButton
          sx={{
            color: activeView === "map" ? colorLinkActive : colorLinkInactive,
          }}
          onClick={() => setActiveView("map")}
        >
          <Icons.MapSharp />
        </IconButton>
      </Tooltip>
      {/* Spacer */}
      <Box sx={{ height: 8 }}></Box>
      {/* Chart View */}
      <Tooltip title="Chart View" placement="right">
        <IconButton
          sx={{
            color: activeView === "chart" ? colorLinkActive : colorLinkInactive,
          }}
          onClick={() => setActiveView("chart")}
        >
          <Icons.BarChartSharp />
        </IconButton>
      </Tooltip>
      {/* Spacer */}
      <Box sx={{ height: 8 }}></Box>
      {/* Table View */}
      <Tooltip title="Table View (coming soon)" placement="right">
        <IconButton
          sx={{
            color: colorLinkInactive,
          }}
        >
          <Icons.TableChartSharp />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

import React, { Dispatch } from "react";
import { AsyncDuckDB } from "@duckdb/duckdb-wasm";
import {
  PlanSelectionAction,
  PlanSelectionState,
  PlansListItem,
} from "lib/plan-selection";
import {
  AggregatedQueryResult,
  Plan,
  PrototypeUser,
  SpacesQueryRow,
} from "lib/types";
import { SpaceSelection } from "./space-selection";

type UserContextType = [
  user: PrototypeUser,
  setUser: (user: PrototypeUser) => void
];
export const UserContext = React.createContext<
  [PrototypeUser, (user: PrototypeUser) => void]
>(null as any as UserContextType);

export const DatabaseContext = React.createContext<AsyncDuckDB>(
  null as any as AsyncDuckDB
);
export const PlansContext = React.createContext<Array<PlansListItem>>([]);
type PlanSelectionContextType = [
  PlanSelectionState,
  Dispatch<PlanSelectionAction>
];
export const PlanSelectionContext =
  React.createContext<PlanSelectionContextType>(
    null as any as PlanSelectionContextType
  );

export type IndustryContextType = {
  allOrgNames: Array<string>;
  industrySpacesResult: Array<SpacesQueryRow>;
};
export const IndustryContext = React.createContext<IndustryContextType>(
  null as any as IndustryContextType
);

export type OrganizationContextType = {
  organizationName: string;
  portfolioSpacesResult: Array<SpacesQueryRow>;
};
export const OrganizationContext = React.createContext<OrganizationContextType>(
  null as any as OrganizationContextType
);

export type PlanContextType = {
  plan: Plan;
  planMeta: PlansListItem;
  floorSpacesResult: Array<SpacesQueryRow>;
  floorAggregatedResult: AggregatedQueryResult;
};
export const PlanContext = React.createContext<PlanContextType>(
  null as any as PlanContextType
);

import { Table } from "apache-arrow";

import { AggregatedQueryResult, SpacesQueryRow } from "lib/types";

export function parseSpacesQuery(queryResponse: Table): Array<SpacesQueryRow> {
  return queryResponse.toArray().map((row) => {
    return {
      ORGANIZATION_NAME: row.ORGANIZATION_NAME,
      BUILDING_NAME: row.BUILDING_NAME,
      FLOOR_NAME: row.FLOOR_NAME,
      SPACE_NAME: row.SPACE_NAME,
      AREA_SQFT: Number(row.AREA_SQFT),
      FUNCTION: row.FUNCTION,
      CAPACITY: Number(row.CAPACITY),
      COST_PER_SQFT: Number(row.COST_PER_SQFT),
      TOTAL_HOURS: Number(row.TOTAL_HOURS),
      USED_HOURS: Number(row.USED_HOURS),
      UNUSED_HOURS: Number(row.UNUSED_HOURS),
      UNUSED_HOURS_PERCENT: Number(row.UNUSED_HOURS_PERCENT),
      PROJECTED_UNUSED_WORKING_HOURS_PER_YEAR: Number(
        row.PROJECTED_UNUSED_WORKING_HOURS_PER_YEAR
      ),
      AVG_OCCUPANCY_WHEN_USED: Number(row.AVG_OCCUPANCY_WHEN_USED),
      AVG_TEAM_DENSITY_WHEN_USED: Number(row.AVG_TEAM_DENSITY_WHEN_USED),
      AREA_ID: row.AREA_ID,
      SPACE_ID: row.SPACE_ID,
    };
  });
}

export function parseAggregatedQuery(
  queryResponse: Table
): AggregatedQueryResult {
  // return queryResponse.toArray().map((row) => {
  //   return {
  //     TOTAL_SPACES: row.TOTAL_SPACES,
  //     TOTAL_AREA_SQFT: row.TOTAL_AREA_SQFT,
  //     SUM_TOTAL_HOURS: row.SUM_TOTAL_HOURS,
  //     SUM_USED_HOURS: Number(row.SUM_USED_HOURS),
  //     SUM_UNUSED_HOURS: Number(row.SUM_UNUSED_HOURS),
  //     AVG_AVG_OCCUPANCY_WHEN_USED: Number(row.AVG_AVG_OCCUPANCY_WHEN_USED),
  //     AVG_AVG_TEAM_DENSITY_WHEN_USED: Number(
  //       row.AVG_AVG_TEAM_DENSITY_WHEN_USED
  //     ),
  //   };
  // })[0];
  return JSON.parse(queryResponse.toString())[0];
}

import { useContext, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import * as colors from "@density/proto-ui-colors";

import { isPolygon } from "lib/types";
import { SpaceSelectionContext } from "lib/space-selection";
import { PlanContext, PlanSelectionContext, UserContext } from "lib/contexts";
import { FloorDetail, FocusedAreaDetail } from "components/space-detail";

export const SpacePanel: React.FunctionComponent = () => {
  const [user] = useContext(UserContext);
  const isEmreSpecial = useMemo(() => {
    return user.mode === "emre-special";
  }, [user.mode]);
  const { plan, planMeta, floorSpacesResult, floorAggregatedResult } =
    useContext(PlanContext);
  const [planSelectionState] = useContext(PlanSelectionContext);
  const { selectedFloor } = planSelectionState;

  const spaceSelection = useContext(SpaceSelectionContext);

  const focusedAreaId = useMemo(() => {
    switch (spaceSelection.state.status) {
      case "no-selection": {
        return null;
      }
      case "selection-only": {
        return spaceSelection.state.selectedAreaId;
      }
      case "peek-only":
      case "selection-and-peek": {
        return spaceSelection.state.peekedAreaId;
      }
    }
  }, [spaceSelection.state]);

  const focusedArea = useMemo(() => {
    if (focusedAreaId === null) return null;
    const area = plan.areas.find((a) => a.id === focusedAreaId);
    if (area && isPolygon(area)) return area;
    return null;
  }, [focusedAreaId, plan.areas]);

  const focusedAreaMetrics = useMemo(() => {
    if (!focusedArea) return null;
    const spaceId = focusedArea.space_id;
    if (!spaceId) return null;
    const spaceData = floorSpacesResult.find((r) => r.SPACE_ID === spaceId);
    if (!spaceData) return null;
    return spaceData;
  }, [focusedArea, floorSpacesResult]);
  return (
    <Box
      sx={{
        backgroundColor: colors.Gray100,
        borderLeft: `1px solid ${colors.Gray100}`,
        width: "240px",
        p: 1,
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      <Typography variant={"h5"}>
        {isEmreSpecial ? "A Floor" : selectedFloor}
      </Typography>
      {focusedArea ? (
        <Typography variant={"h6"}>
          {isEmreSpecial ? "A Space" : focusedArea.name}
        </Typography>
      ) : null}

      {focusedArea ? (
        <FocusedAreaDetail
          focusedArea={focusedArea}
          focusedAreaMetrics={focusedAreaMetrics}
        />
      ) : floorAggregatedResult ? (
        <FloorDetail
          planMeta={planMeta}
          floorAggregatedResult={floorAggregatedResult}
        />
      ) : (
        <div>No valid results for this floor.</div>
      )}
    </Box>
  );
};

import * as d3 from "d3";
import { Feature, FeatureCollection } from "geojson";

import { Area, AreaPolygon, isPolygon } from "lib/types";

export function createFeatureCollection(
  areas: Array<Area>,
  planData: any
): FeatureCollection {
  const scale = planData.image_pixels_per_meter;
  const features = areas.filter<AreaPolygon>(isPolygon).map<Feature>((area) => {
    return {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          area.polygon_verticies
            .concat([area.polygon_verticies[0]])
            .map((v) => [
              Math.round(v.x_from_origin_meters * scale),
              Math.round(v.y_from_origin_meters * scale),
            ]),
        ],
      },
      properties: {
        id: area.id,
        name: area.name,
        space_id: area.space_id,
        area_sqft:
          Math.abs(
            d3.polygonArea(
              area.polygon_verticies.map((v) => [
                v.x_from_origin_meters,
                v.y_from_origin_meters,
              ])
            )
          ) * 10.764,
      },
    };
  });

  return {
    type: "FeatureCollection",
    features,
  };
}

import { ToggleButtonGroup, ToggleButton } from "@mui/material";

export type VisualizationScope = "floor" | "portfolio" | "industry";

type Props = {
  value: VisualizationScope;
  onChange: (value: VisualizationScope) => void;
};

export const ScopeToggle: React.FunctionComponent<Props> = (props) => {
  return (
    <ToggleButtonGroup
      value={props.value}
      exclusive
      size="small"
      onChange={(e, value) => {
        props.onChange(value as VisualizationScope);
      }}
    >
      <ToggleButton value="floor">Floor</ToggleButton>
      <ToggleButton value="portfolio">Portfolio</ToggleButton>
      <ToggleButton value="industry">Industry</ToggleButton>
    </ToggleButtonGroup>
  );
};

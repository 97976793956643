import React, { useMemo } from "react";
import { List, ListItem, styled, Typography } from "@mui/material";
import * as colors from "@density/proto-ui-colors";

import {
  AggregatedQueryResult,
  AreaPolygon,
  Nullable,
  SpacesQueryRow,
} from "lib/types";
import { PlansListItem } from "lib/plan-selection";
import {
  formatAsDollars,
  formatAsDollarsAndCents,
  formatAsInteger,
  formatAsOccupancy,
  formatAsPercent,
  formatAsTeamDensity,
  isNumber,
} from "lib/formatters";

type FloorMetrics = {
  totalSpaces: number;
  totalArea: number;
  costPerSqft: number;
  totalCost: number;
  avgDensityWhenUsed: number;
  avgOccupancyWhenUsed: number;
  totalHours: number;
  unusedHours: number;
  unusedHoursPercent: number;
};

type SpaceMetrics = {
  area: number;
  costPerSqft: number;
  costAnnual: number;
  avgOccupancyWhenUsed: number;
  avgDensityWhenUsed: number;
  unusedHours: number;
  totalHours: number;
  unusedHoursPercent: number;
  costOfUnusedHoursAnnual: number;
};

const ListItemLabel = styled("div")({
  textTransform: "uppercase",
  fontSize: 12,
  color: colors.Gray400,
});

const MetricDisplay: React.FunctionComponent<
  React.PropsWithChildren & {
    label: string;
  }
> = ({ label, children }) => {
  return (
    <ListItem sx={{ display: "block" }}>
      <ListItemLabel>{label}</ListItemLabel>
      <Typography variant="body1" display={"block"}>
        {children}
      </Typography>
    </ListItem>
  );
};

export const FocusedAreaDetail: React.FunctionComponent<{
  focusedArea: AreaPolygon;
  focusedAreaMetrics: SpacesQueryRow | null;
}> = ({ focusedArea, focusedAreaMetrics }) => {
  const metrics: Partial<Nullable<SpaceMetrics>> = useMemo(() => {
    const area = focusedAreaMetrics?.AREA_SQFT;
    const costPerSqft = focusedAreaMetrics?.COST_PER_SQFT;
    const costAnnual =
      isNumber(area) && isNumber(costPerSqft) ? area * costPerSqft : null;
    const avgOccupancyWhenUsed = focusedAreaMetrics?.AVG_OCCUPANCY_WHEN_USED;
    const avgDensityWhenUsed = focusedAreaMetrics?.AVG_TEAM_DENSITY_WHEN_USED;
    const totalHours = focusedAreaMetrics?.TOTAL_HOURS;
    const unusedHours = focusedAreaMetrics?.UNUSED_HOURS;
    const unusedHoursPercent = focusedAreaMetrics?.UNUSED_HOURS_PERCENT;
    const costOfUnusedHoursAnnual =
      isNumber(unusedHoursPercent) && isNumber(costAnnual)
        ? unusedHoursPercent * costAnnual
        : null;
    return {
      area,
      costPerSqft,
      costAnnual,
      avgOccupancyWhenUsed,
      avgDensityWhenUsed,
      totalHours,
      unusedHours,
      unusedHoursPercent,
      costOfUnusedHoursAnnual,
    };
  }, [focusedAreaMetrics]);

  return (
    <List dense>
      <MetricDisplay label="Area">
        {`${formatAsInteger(metrics.area)} sqft`}
      </MetricDisplay>
      <MetricDisplay label="Cost Per Sqft (annual)">
        {formatAsDollarsAndCents(metrics.costPerSqft)}
      </MetricDisplay>
      <MetricDisplay label="Cost (annual)">
        {formatAsDollars(metrics.costAnnual)}
      </MetricDisplay>
      <MetricDisplay label="Avg. Occupancy (when used)">
        {formatAsOccupancy(metrics.avgOccupancyWhenUsed)}
      </MetricDisplay>
      <MetricDisplay label="Avg. Density (when used)">
        {formatAsTeamDensity(metrics.avgDensityWhenUsed)}
      </MetricDisplay>
      <MetricDisplay label="Unused / Total Hours">
        {`${formatAsInteger(metrics.unusedHours)} / ${formatAsInteger(
          metrics.totalHours
        )}`}
      </MetricDisplay>
      <MetricDisplay label="Unused Percent">
        {formatAsPercent(metrics.unusedHoursPercent)}
      </MetricDisplay>
      <MetricDisplay label="Cost of Unused Hours (projected annual)">
        {formatAsDollars(metrics.costOfUnusedHoursAnnual)}
      </MetricDisplay>
    </List>
  );
};

export const FloorDetail: React.FunctionComponent<{
  planMeta: PlansListItem;
  floorAggregatedResult: AggregatedQueryResult | null;
}> = ({ planMeta, floorAggregatedResult }) => {
  const metrics: Partial<Nullable<FloorMetrics>> = useMemo(() => {
    const totalSpaces = floorAggregatedResult?.TOTAL_SPACES;
    const totalArea = floorAggregatedResult?.TOTAL_AREA_SQFT;
    const costPerSqft = planMeta.COST_PER_SQFT;
    const totalCost = totalArea && costPerSqft ? totalArea * costPerSqft : null;
    const avgDensityWhenUsed =
      floorAggregatedResult?.AVG_AVG_TEAM_DENSITY_WHEN_USED;
    const avgOccupancyWhenUsed =
      floorAggregatedResult?.AVG_AVG_OCCUPANCY_WHEN_USED;
    const totalHours = floorAggregatedResult?.SUM_TOTAL_HOURS;
    const usedHours = floorAggregatedResult?.SUM_USED_HOURS;
    const unusedHours = floorAggregatedResult?.SUM_UNUSED_HOURS;
    const unusedHoursPercent =
      isNumber(unusedHours) && isNumber(totalHours) && totalHours > 0
        ? unusedHours / totalHours
        : null;
    return {
      totalSpaces,
      totalArea,
      costPerSqft,
      totalCost,
      avgDensityWhenUsed,
      avgOccupancyWhenUsed,
      totalHours,
      usedHours,
      unusedHours,
      unusedHoursPercent,
    };
  }, [planMeta.COST_PER_SQFT, floorAggregatedResult]);

  return (
    <List>
      <MetricDisplay label="Total Spaces">
        {formatAsInteger(metrics.totalSpaces)}
      </MetricDisplay>
      <MetricDisplay label="Total Area">
        {`${formatAsInteger(metrics.totalArea)} sqft`}
      </MetricDisplay>
      <MetricDisplay label="Cost Per Sqft (annual)">
        {formatAsDollarsAndCents(metrics.costPerSqft)}
      </MetricDisplay>
      <MetricDisplay label="Total Cost (annual)">
        {formatAsDollars(metrics.totalCost)}
      </MetricDisplay>
      <MetricDisplay label="Avg. Density (when used)">
        {formatAsTeamDensity(metrics.avgDensityWhenUsed)}
      </MetricDisplay>
      <MetricDisplay label="Avg. Occupancy (when used)">
        {formatAsOccupancy(metrics.avgOccupancyWhenUsed)}
      </MetricDisplay>
      <MetricDisplay label="Unused / Total Hours">
        {`${formatAsInteger(metrics.unusedHours)} / ${formatAsInteger(
          metrics.totalHours
        )}`}
      </MetricDisplay>
      <MetricDisplay label="Unused Percent">
        {formatAsPercent(metrics.unusedHoursPercent)}
      </MetricDisplay>
    </List>
  );
};

// Adapted from https://github.com/duckdb/duckdb-wasm/blob/952312ac818bc0850626b4810dc87450f65cea08/packages/react-duckdb/src/resolvable.ts

export enum ResolvableStatus {
  NONE,
  RUNNING,
  FAILED,
  COMPLETED,
}

export type Resolvable<Value, Error = unknown, Progress = null> =
  | { status: ResolvableStatus.NONE }
  | { status: ResolvableStatus.RUNNING; progress: Progress }
  | { status: ResolvableStatus.FAILED; error: Error }
  | { status: ResolvableStatus.COMPLETED; value: Value };

export function completeWith<Value>(value: Value) {
  return {
    status: ResolvableStatus.COMPLETED as const,
    value,
  };
}

export function failWith<Error = unknown>(error: Error) {
  return {
    status: ResolvableStatus.FAILED as const,
    error,
  };
}

export function updateRunning<Progress = null>(progress: Progress) {
  return {
    status: ResolvableStatus.RUNNING as const,
    progress,
  };
}

import React, { Reducer, useCallback, useReducer } from "react";

export type SpaceSelection = {
  state: SpaceSelectionState;
  onAreaMouseEnter: (areaId: string) => void;
  onAreaMouseLeave: (areaId: string) => void;
  onAreaClick: (areaId: string) => void;
  onBackgroundClick: () => void;
  onEscapeKeyDown: () => void;
};

export const SpaceSelectionContext = React.createContext<SpaceSelection>(
  null as any as SpaceSelection
);

type SpaceSelectionState =
  | {
      status: "no-selection";
    }
  | {
      status: "peek-only";
      peekedAreaId: string;
    }
  | {
      status: "selection-only";
      selectedAreaId: string;
    }
  | {
      status: "selection-and-peek";
      selectedAreaId: string;
      peekedAreaId: string;
    };

type SpaceSelectionAction =
  | {
      type: "area.mouseenter";
      areaId: string;
    }
  | {
      type: "area.mouseleave";
      areaId: string;
    }
  | {
      type: "area.click";
      areaId: string;
    }
  | {
      type: "background.click";
    }
  | { type: "keyboard.escape" };

const reducer: Reducer<SpaceSelectionState, SpaceSelectionAction> = (
  state,
  action
) => {
  switch (action.type) {
    case "area.mouseenter": {
      switch (state.status) {
        case "no-selection": {
          return {
            status: "peek-only",
            peekedAreaId: action.areaId,
          };
        }
        case "selection-only": {
          return {
            status: "selection-and-peek",
            selectedAreaId: state.selectedAreaId,
            peekedAreaId: action.areaId,
          };
        }
        default: {
          return state;
        }
      }
    }
    case "area.mouseleave": {
      switch (state.status) {
        case "peek-only": {
          return {
            status: "no-selection",
          };
        }
        case "selection-and-peek": {
          return {
            status: "selection-only",
            selectedAreaId: state.selectedAreaId,
          };
        }
        default: {
          return state;
        }
      }
    }
    case "area.click": {
      switch (state.status) {
        // probably not possible? how could you click without mouseover first?
        // in any case, force sanity if this does happen:
        case "no-selection": {
          return {
            status: "selection-only",
            selectedAreaId: action.areaId,
          };
        }
        // also probably not possible...
        case "selection-only": {
          if (state.selectedAreaId !== action.areaId) {
            return {
              status: "selection-only",
              selectedAreaId: action.areaId,
            };
          } else {
            return {
              status: "no-selection",
            };
          }
        }
        case "peek-only": {
          return {
            status: "selection-and-peek",
            selectedAreaId: action.areaId,
            peekedAreaId: action.areaId,
          };
        }
        case "selection-and-peek": {
          if (state.selectedAreaId !== action.areaId) {
            return {
              status: "selection-and-peek",
              selectedAreaId: action.areaId,
              peekedAreaId: state.peekedAreaId,
            };
          }
          return {
            status: "peek-only",
            peekedAreaId: state.peekedAreaId,
          };
        }
      }
      break;
    }
    case "background.click": {
      return {
        status: "no-selection",
      };
    }
    case "keyboard.escape": {
      switch (state.status) {
        case "selection-only": {
          return {
            status: "no-selection",
          };
        }
        case "selection-and-peek": {
          return {
            status: "peek-only",
            peekedAreaId: state.peekedAreaId,
          };
        }
        default: {
          return state;
        }
      }
    }
  }
};

const initialState: SpaceSelectionState = {
  status: "no-selection",
};

export function useSpaceSelection() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onAreaMouseEnter = useCallback(
    (areaId: string) => {
      dispatch({ type: "area.mouseenter", areaId });
    },
    [dispatch]
  );
  const onAreaMouseLeave = useCallback(
    (areaId: string) => {
      dispatch({ type: "area.mouseleave", areaId });
    },
    [dispatch]
  );
  const onAreaClick = useCallback(
    (areaId: string) => {
      dispatch({ type: "area.click", areaId });
    },
    [dispatch]
  );
  const onBackgroundClick = useCallback(() => {
    dispatch({ type: "background.click" });
  }, [dispatch]);

  const onEscapeKeyDown = useCallback(() => {
    dispatch({ type: "keyboard.escape" });
  }, [dispatch]);

  return {
    state,
    onAreaMouseEnter,
    onAreaMouseLeave,
    onAreaClick,
    onBackgroundClick,
    onEscapeKeyDown,
  };
}

import React, { useContext, useMemo } from "react";
import {
  Box,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormGroup,
} from "@mui/material";

import { PlanSelectionContext } from "lib/contexts";
import { ColorByOption } from "lib/plan-selection";
import { ScopeToggle } from "components/scope-toggle";
import { FloorplanDisplay } from "components/floorplan-display";

import { SpaceSelectionContext } from "lib/space-selection";

export const MapView: React.FunctionComponent = () => {
  const [planSelectionState, planSelectionDispatch] =
    useContext(PlanSelectionContext);

  const { visualizationScope, colorBy } = planSelectionState;

  const spaceSelection = useContext(SpaceSelectionContext);

  const focusedAreaId = useMemo(() => {
    switch (spaceSelection.state.status) {
      case "no-selection": {
        return null;
      }
      case "selection-only": {
        return spaceSelection.state.selectedAreaId;
      }
      case "peek-only":
      case "selection-and-peek": {
        return spaceSelection.state.peekedAreaId;
      }
    }
  }, [spaceSelection.state]);

  return (
    <React.Fragment>
      <Toolbar>
        <FormControl size={"small"} sx={{ m: 1 }}>
          <InputLabel>Metric</InputLabel>
          <Select
            label={"Metric"}
            value={colorBy}
            onChange={(evt) => {
              planSelectionDispatch({
                type: "select-color-by",
                value: evt.target.value as ColorByOption,
              });
            }}
          >
            <MenuItem value={"density"}>Density</MenuItem>
            <MenuItem value={"unused-hours"}>Unused Hours</MenuItem>
          </Select>
          <FormHelperText>
            {colorBy === "density"
              ? "sqft per person, when used"
              : "% of working hours unoccupied"}
          </FormHelperText>
        </FormControl>
      </Toolbar>
      <FloorplanDisplay
        focusedAreaId={focusedAreaId}
        onMouseEnterArea={spaceSelection.onAreaMouseEnter}
        onMouseLeaveArea={spaceSelection.onAreaMouseLeave}
        onClickArea={spaceSelection.onAreaClick}
        onClickBackground={spaceSelection.onBackgroundClick}
        colorBy={colorBy}
        visualizationScope={visualizationScope}
      />
      <Box
        sx={{
          height: "140px",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "center",
          flexWrap: "wrap",
          p: 2,
        }}
      >
        <FormGroup>
          <ScopeToggle
            value={visualizationScope}
            onChange={(v) =>
              planSelectionDispatch({
                type: "select-visualization-scope",
                scope: v,
              })
            }
          />
        </FormGroup>
      </Box>
    </React.Fragment>
  );
};

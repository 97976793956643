import { useRef, useState, useContext, useMemo, useEffect } from "react";
import * as d3 from "d3";
import { Box } from "@mui/material";
import useSize from "@react-hook/size";
import * as colors from "@density/proto-ui-colors";

import {
  PlanSelectionContext,
  IndustryContext,
  UserContext,
} from "lib/contexts";
import { SpaceSelectionContext } from "lib/space-selection";

const padding = {
  top: 32,
  right: 32,
  bottom: 64,
  left: 84,
};

export function ChartView() {
  const wrapperElementRef = useRef<HTMLElement>(null);
  // const canvasElementRef = useRef<HTMLCanvasElement>(null);
  const xAxisGroupElementRef = useRef<SVGGElement>(null);
  const yAxisGroupElementRef = useRef<SVGGElement>(null);
  const [width, height] = useSize(wrapperElementRef);

  const { industrySpacesResult } = useContext(IndustryContext);
  const [
    { selectedOrg, selectedBuilding, selectedFloor },
    planSelectionDispatch,
  ] = useContext(PlanSelectionContext);
  const spaces = useMemo(() => {
    return industrySpacesResult
      .slice()
      .sort(
        (a, b) =>
          d3.descending(
            a.ORGANIZATION_NAME === selectedOrg &&
              a.BUILDING_NAME === selectedBuilding &&
              a.FLOOR_NAME === selectedFloor
              ? 1
              : 0,
            b.ORGANIZATION_NAME === selectedOrg &&
              b.BUILDING_NAME === selectedBuilding &&
              b.FLOOR_NAME === selectedFloor
              ? 1
              : 0
          ) || d3.descending(a.AREA_SQFT, b.AREA_SQFT)
      )
      .reverse();
  }, [industrySpacesResult, selectedOrg, selectedBuilding, selectedFloor]);

  const spaceSelection = useContext(SpaceSelectionContext);
  const focusedAreaId = useMemo(() => {
    switch (spaceSelection.state.status) {
      case "no-selection": {
        return null;
      }
      case "selection-only": {
        return spaceSelection.state.selectedAreaId;
      }
      case "peek-only":
      case "selection-and-peek": {
        return spaceSelection.state.peekedAreaId;
      }
    }
  }, [spaceSelection.state]);

  const focusedSpace = useMemo(() => {
    if (focusedAreaId === null) return null;
    const space = spaces.find((s) => s.AREA_ID === focusedAreaId);
    if (!space) return null;
    return space;
  }, [spaces, focusedAreaId]);

  const [user] = useContext(UserContext);

  const scaleX = useMemo(() => {
    return d3
      .scaleLinear()
      .domain([0, 1])
      .range([padding.left, width - padding.right]);
  }, [width]);

  const scaleY = useMemo(() => {
    const domain = d3.extent(spaces, (d) => d.AVG_TEAM_DENSITY_WHEN_USED) as [
      number,
      number
    ];
    return (
      d3
        // .scaleLinear()
        .scaleSqrt()
        .domain(domain)
        .range([height - padding.bottom, padding.top])
        .nice()
    );
  }, [spaces, height]);

  const scaleSize = useMemo(() => {
    const domain = d3.extent(spaces, (d) => d.AREA_SQFT) as [number, number];
    return d3.scaleSqrt().domain(domain).range([3, 32]);
  }, [spaces]);

  // Axes
  useEffect(() => {
    const xAxisGroupElement = xAxisGroupElementRef.current;
    const yAxisGroupElement = yAxisGroupElementRef.current;
    if (!(xAxisGroupElement && yAxisGroupElement)) return;

    const xAxis = d3
      .axisBottom(scaleX)
      .tickFormat(d3.format("~%"))
      .tickPadding(8);
    const yAxis = d3.axisLeft(scaleY).tickPadding(8);

    d3.select(xAxisGroupElement)
      .call(xAxis)
      .selectAll("text")
      .attr("font-size", 13);
    d3.select(yAxisGroupElement)
      .call(yAxis)
      .selectAll("text")
      .attr("font-size", 13);
  }, [scaleX, scaleY, width, height]);

  // useEffect(() => {
  //   const canvas = canvasElementRef.current;
  //   if (!canvas) return;
  //   const ctx = canvas.getContext("2d");
  //   if (!ctx) return;
  //   ctx.clearRect(0, 0, canvas.width, canvas.height);
  //   ctx.globalAlpha = 1;
  //   for (const space of spaces) {
  //     const isActive =
  //       space.ORGANIZATION_NAME === selectedOrg &&
  //       space.BUILDING_NAME === selectedBuilding &&
  //       space.FLOOR_NAME === selectedFloor;
  //     ctx.globalAlpha = isActive ? 0.7 : 0.1;
  //     const cx = scaleX(space.UNUSED_HOURS_PERCENT);
  //     const cy = scaleY(space.AVG_TEAM_DENSITY_WHEN_USED);
  //     const r = scaleSize(space.AREA_SQFT);
  //     ctx.beginPath();
  //     // ctx.moveTo(cx, cy);
  //     ctx.arc(cx, cy, r, 0, Math.PI * 2);
  //     ctx.stroke();
  //     if (isActive) {
  //       ctx.fill();
  //     }
  //   }
  // }, [
  //   spaces,
  //   selectedOrg,
  //   selectedBuilding,
  //   selectedFloor,
  //   scaleX,
  //   scaleY,
  //   scaleSize,
  // ]);

  return (
    <Box width={"100%"} height={"100%"} ref={wrapperElementRef}>
      {/* <canvas
        style={{ position: "absolute" }}
        width={width}
        height={height}
        ref={canvasElementRef}
      /> */}
      <svg style={{ position: "absolute" }} width={width} height={height}>
        <text
          y={height - padding.bottom + 48}
          x={padding.left + (width - padding.left - padding.right) / 2}
          textAnchor={"middle"}
          fontFamily={"Roboto"}
          fontSize={14}
        >
          Unused Hours (%)
        </text>

        <text
          textAnchor={"middle"}
          fontFamily={"Roboto"}
          fontSize={14}
          transform={`translate(${padding.left - 56}, ${
            padding.top + (height - padding.top - padding.bottom) / 2
          }) rotate(-90)`}
        >
          Density (sqft / person)
        </text>
        <g
          ref={xAxisGroupElementRef}
          transform={`translate(0, ${height - padding.bottom})`}
        />
        <g
          ref={yAxisGroupElementRef}
          transform={`translate(${padding.left}, 0)`}
        />
        <g>
          {spaces.map((space) => {
            const isInPortfolio = space.ORGANIZATION_NAME === selectedOrg;
            const isDisabled =
              user.mode !== "superuser" &&
              space.ORGANIZATION_NAME !== user.organizationName;
            const isInActiveFloor =
              isInPortfolio &&
              space.BUILDING_NAME === selectedBuilding &&
              space.FLOOR_NAME === selectedFloor;
            const isFocused = isDisabled
              ? false
              : focusedSpace?.SPACE_ID === space.SPACE_ID;
            return (
              <circle
                key={space.SPACE_ID}
                cx={scaleX(space.UNUSED_HOURS_PERCENT)}
                cy={scaleY(space.AVG_TEAM_DENSITY_WHEN_USED)}
                r={scaleSize(space.AREA_SQFT)}
                fill={
                  isInActiveFloor
                    ? colors.Blue700
                    : isInPortfolio
                    ? colors.Blue200
                    : "transparent"
                }
                stroke={colors.Gray900}
                strokeWidth={isFocused ? 3 : 1}
                strokeOpacity={isInActiveFloor ? 1 : 0.2}
                onClick={() => {
                  if (isDisabled) return;
                  planSelectionDispatch({
                    type: "select-org",
                    org: space.ORGANIZATION_NAME,
                  });
                  planSelectionDispatch({
                    type: "select-building",
                    building: space.BUILDING_NAME,
                  });
                  planSelectionDispatch({
                    type: "select-floor",
                    floor: space.FLOOR_NAME,
                  });
                  spaceSelection.onAreaClick(space.AREA_ID);
                }}
                onMouseEnter={() => {
                  if (isDisabled) return;
                  spaceSelection.onAreaMouseEnter(space.AREA_ID);
                }}
                onMouseLeave={() => {
                  if (isDisabled) return;
                  spaceSelection.onAreaMouseLeave(space.AREA_ID);
                }}
              />
            );
          })}
        </g>
      </svg>
    </Box>
  );
}

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box } from "@mui/material";
import { TokenProvider } from "@densityco/lib-common-auth";

import { useDuckDB } from "lib/db";
import {
  completeWith,
  Resolvable,
  ResolvableStatus,
  updateRunning,
} from "lib/resolvable";
import { PlansListItem, usePlanSelection } from "lib/plan-selection";
import {
  DatabaseContext,
  IndustryContext,
  IndustryContextType,
  OrganizationContext,
  OrganizationContextType,
  PlanContext,
  PlanContextType,
  PlansContext,
  PlanSelectionContext,
  UserContext,
} from "lib/contexts";
import {
  ActiveView,
  AggregatedQueryResult,
  Plan,
  PrototypeUser,
  SpacesQueryRow,
  Wrapper,
} from "lib/types";
import { Loader } from "components/loader";
import { ErrorState } from "components/error-state";
import { TopNavigation } from "components/top-navigation";
import { MapView } from "app/map-view";
import { ChartView } from "app/chart-view";
import { parseAggregatedQuery, parseSpacesQuery } from "lib/query";
import { LeftMenuBar } from "components/side-navigation";
import { SpaceSelectionContext, useSpaceSelection } from "lib/space-selection";
import { SpacePanel } from "components/space-panel";
import { createFeatureCollection } from "lib/feature-collection";

const PUBLIC_URL = process.env.PUBLIC_URL;

const Root: Wrapper = (props) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
        boxSizing: "border-box",
      }}
    >
      {props.children}
    </Box>
  );
};

const MainLayout: Wrapper = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
};

const MainPanel: Wrapper = ({ children }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1, p: 1 }}>
      {children}
    </Box>
  );
};

const USER_STORAGE_KEY = "io.density.prototype.user";
const TOKEN_STORAGE_KEY = "io.density.prototype.token";

const UserLoader: Wrapper = (props) => {
  const [user, setUser] = useState<PrototypeUser>(() => {
    const _userFromStorage = localStorage.getItem(USER_STORAGE_KEY);
    const defaultUser: PrototypeUser = {
      mode: "superuser",
    };
    const initialUser: PrototypeUser = _userFromStorage
      ? JSON.parse(_userFromStorage)
      : defaultUser;
    return initialUser;
  });

  const setAndStoreUser = useCallback<(user: PrototypeUser) => void>(
    (user) => {
      setUser(user);
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
    },
    [setUser]
  );

  return (
    <TokenProvider
      loginHost={"https://api.density.io"}
      tokenLocalStorageKey={TOKEN_STORAGE_KEY}
    >
      {(tokenProviderData) => {
        return (
          <UserContext.Provider value={[user, setAndStoreUser]}>
            {props.children}
          </UserContext.Provider>
        );
      }}
    </TokenProvider>
  );
};

const DatabaseLoader: Wrapper = (props) => {
  const resolveDB = useDuckDB();

  switch (resolveDB.status) {
    case ResolvableStatus.NONE:
    case ResolvableStatus.RUNNING: {
      return <Loader>Loading database...</Loader>;
    }
    case ResolvableStatus.FAILED: {
      return <ErrorState>Error loading database.</ErrorState>;
    }
    case ResolvableStatus.COMPLETED: {
      const { value } = resolveDB;

      return (
        <DatabaseContext.Provider value={value}>
          {props.children}
        </DatabaseContext.Provider>
      );
    }
  }
};

const ParquetDataLoader: Wrapper = (props) => {
  const db = useContext(DatabaseContext);

  const [state, setState] = useState<Resolvable<null>>({
    status: ResolvableStatus.NONE,
  });

  useEffect(() => {
    setState(updateRunning(null));
    (async () => {
      // await db.registerFileURL(
      //   "building_cost.csv",
      //   PUBLIC_URL + "building_cost.csv"
      // );
      // {
      //   const res = await fetch(PUBLIC_URL + "building_cost.csv");
      //   await db.registerFileText("building_cost.csv", await res.text());
      // }

      await db.registerFileURL(
        "occupancy.parquet",
        PUBLIC_URL + "occupancy_grouped_by_hour.parquet"
      );
      const res = await fetch(PUBLIC_URL + "occupancy_grouped_by_hour.parquet");
      await db.registerFileBuffer(
        "occupancy.parquet",
        new Uint8Array(await res.arrayBuffer())
      );
      const conn = await db.connect();
      await conn.query(`
        CREATE OR REPLACE VIEW occupancy AS (
          SELECT *
          FROM 'occupancy.parquet'
        )
      `);
      await conn.close();
      setState({ status: ResolvableStatus.COMPLETED, value: null });
    })();
  }, [db]);

  switch (state.status) {
    case ResolvableStatus.NONE:
    case ResolvableStatus.RUNNING: {
      return <Loader>Loading datasets...</Loader>;
    }
    case ResolvableStatus.FAILED: {
      return <ErrorState>Error</ErrorState>;
    }
    case ResolvableStatus.COMPLETED: {
      return <React.Fragment>{props.children}</React.Fragment>;
    }
  }
};

const PlansLoader: Wrapper = (props) => {
  const [user] = useContext(UserContext);
  const db = useContext(DatabaseContext);
  const [state, setState] = useState<Resolvable<Array<PlansListItem>>>({
    status: ResolvableStatus.NONE,
  });

  useEffect(() => {
    setState(updateRunning(null));
    (async () => {
      let plans = [];

      const conn = await db.connect();

      await conn.query(`
          CREATE OR REPLACE VIEW agg_industry AS (
            SELECT
              ORGANIZATION_NAME,
              BUILDING_NAME,
              FLOOR_NAME,
              SPACE_NAME,
              AREA_SQFT,
              COST_PER_SQFT,
              FUNCTION,
              CAPACITY,
              COUNT(*) AS TOTAL_HOURS,
              COUNT(CASE WHEN USED THEN 1 END) AS USED_HOURS,
              COUNT(CASE WHEN NOT USED THEN 1 END) AS UNUSED_HOURS,
              CAST(COUNT(CASE WHEN NOT USED THEN 1 END) AS FLOAT) / COUNT(*) AS UNUSED_HOURS_PERCENT,
              ROUND((CAST(COUNT(CASE WHEN NOT USED THEN 1 END) AS FLOAT) / COUNT(*)) * 2600) AS PROJECTED_UNUSED_WORKING_HOURS_PER_YEAR,
              AVG(CASE WHEN USED THEN OCCUPANCY END) AS AVG_OCCUPANCY_WHEN_USED,
              LEAST(AREA_SQFT, (AREA_SQFT / AVG(CASE WHEN USED THEN OCCUPANCY END))) AS AVG_TEAM_DENSITY_WHEN_USED,
              SPACE_ID,
              AREA_ID
            FROM occupancy
            GROUP BY ORGANIZATION_NAME, BUILDING_NAME, FLOOR_NAME, SPACE_NAME, AREA_SQFT, COST_PER_SQFT, FUNCTION, CAPACITY, SPACE_ID, AREA_ID 
            HAVING COUNT(CASE WHEN USED THEN 1 END) > 0
            ORDER BY ORGANIZATION_NAME, BUILDING_NAME, FLOOR_NAME, SPACE_NAME
          )
        `);

      await conn.query(`
          CREATE OR REPLACE VIEW plans AS (
            SELECT DISTINCT
              ORGANIZATION_NAME,
              BUILDING_NAME,
              COST_PER_SQFT,
              FLOOR_NAME,
              PLAN_ID,
            FROM occupancy
          );
        `);

      const result = await conn.query(`
          SELECT *
          FROM plans
        `);

      plans = result.toArray().map((p) => {
        return {
          ORGANIZATION_NAME: p.ORGANIZATION_NAME,
          BUILDING_NAME: p.BUILDING_NAME,
          COST_PER_SQFT: p.COST_PER_SQFT,
          FLOOR_NAME: p.FLOOR_NAME,
          PLAN_ID: p.PLAN_ID,
        };
      });
      await conn.close();

      if (user.mode === "user") {
        plans = plans.filter(
          (p) => p.ORGANIZATION_NAME === user.organizationName
        );
      } else if (user.mode === "emre-special") {
        plans = plans.filter((p) => p.ORGANIZATION_NAME === "LinkedIn");
      }
      setState(completeWith(plans));
    })();
  }, [db, user]);

  switch (state.status) {
    case ResolvableStatus.NONE:
    case ResolvableStatus.RUNNING: {
      return <Loader>Loading plans...</Loader>;
    }
    case ResolvableStatus.FAILED: {
      return <ErrorState>Error loading plans.</ErrorState>;
    }
    case ResolvableStatus.COMPLETED: {
      return (
        <PlansContext.Provider value={state.value}>
          {props.children}
        </PlansContext.Provider>
      );
    }
  }
};

const SpaceSelectionProvider: Wrapper = ({ children }) => {
  const spaceSelection = useSpaceSelection();

  // Handle the escape key action here at top-level for now
  useEffect(() => {
    const action = spaceSelection.onEscapeKeyDown;
    const handler = (evt: KeyboardEvent) => {
      if (evt.key === "Escape") {
        action();
      }
    };
    window.document.addEventListener("keydown", handler);

    return () => {
      window.document.removeEventListener("keydown", handler);
    };
  }, [spaceSelection.onEscapeKeyDown]);

  return (
    <SpaceSelectionContext.Provider value={spaceSelection}>
      {children}
    </SpaceSelectionContext.Provider>
  );
};

export const App: React.FunctionComponent = () => {
  const [activeView, setActiveView] = useState<ActiveView>("map");

  return (
    <Root>
      <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
        <LeftMenuBar {...{ activeView, setActiveView }} />
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <UserLoader>
            <DatabaseLoader>
              <ParquetDataLoader>
                <PlansLoader>
                  <IndustryLoader>
                    <PlanSelectionLoader>
                      <TopNavigation />
                      <SpaceSelectionProvider>
                        <OrganizationLoader>
                          <PlanLoader>
                            <MainLayout>
                              <MainPanel>
                                {activeView === "map" ? (
                                  <MapView />
                                ) : (
                                  <ChartView />
                                )}
                              </MainPanel>
                              <SpacePanel />
                            </MainLayout>
                          </PlanLoader>
                        </OrganizationLoader>
                      </SpaceSelectionProvider>
                    </PlanSelectionLoader>
                  </IndustryLoader>
                </PlansLoader>
              </ParquetDataLoader>
            </DatabaseLoader>
          </UserLoader>
        </Box>
      </Box>
    </Root>
  );
};

const PlanSelectionLoader: Wrapper = ({ children }) => {
  const plans = useContext(PlansContext);
  const { state, dispatch } = usePlanSelection(plans);

  return (
    <PlanSelectionContext.Provider value={[state, dispatch]}>
      {children}
    </PlanSelectionContext.Provider>
  );
};

const IndustryLoader: Wrapper = ({ children }) => {
  const db = useContext(DatabaseContext);
  const [state, setState] = useState<Resolvable<IndustryContextType>>({
    status: ResolvableStatus.NONE,
  });
  useEffect(() => {
    setState(updateRunning(null));
    (async () => {
      const conn = await db.connect();

      const industrySpacesQuery = await conn.query(`
        SELECT *
        FROM agg_industry
      `);

      const allOrgNames = (
        await conn.query(`
        SELECT DISTINCT
          ORGANIZATION_NAME
        FROM agg_industry
      `)
      )
        .toArray()
        .map((row) => row.ORGANIZATION_NAME);

      const industrySpacesResult = parseSpacesQuery(industrySpacesQuery);

      setState(
        completeWith({
          allOrgNames,
          industrySpacesResult,
        })
      );
    })();
  }, [db]);

  switch (state.status) {
    case ResolvableStatus.NONE:
    case ResolvableStatus.RUNNING: {
      return <Loader>Loading occupancy data...</Loader>;
    }
    case ResolvableStatus.FAILED: {
      return <ErrorState>Error loading occupancy data.</ErrorState>;
    }
    case ResolvableStatus.COMPLETED: {
      return (
        <IndustryContext.Provider value={state.value}>
          {children}
        </IndustryContext.Provider>
      );
    }
  }
};

const OrganizationLoader: Wrapper = ({ children }) => {
  const db = useContext(DatabaseContext);
  const [planSelectionState] = useContext(PlanSelectionContext);
  const { selectedOrg } = planSelectionState;
  const [state, setState] = useState<Resolvable<OrganizationContextType>>({
    status: ResolvableStatus.NONE,
  });
  useEffect(() => {
    setState(updateRunning(null));
    (async () => {
      const conn = await db.connect();
      await conn.query(`
      CREATE OR REPLACE VIEW agg_org AS (
        SELECT *
        FROM agg_industry
        WHERE ORGANIZATION_NAME = '${selectedOrg}'
      )
    `);

      const portfolioSpacesQuery = await conn.query(`
        SELECT *
        FROM agg_org
      `);

      const portfolioSpacesResult = parseSpacesQuery(portfolioSpacesQuery);

      setState(
        completeWith({
          organizationName: selectedOrg,
          portfolioSpacesResult,
        })
      );
    })();
  }, [db, selectedOrg]);

  switch (state.status) {
    case ResolvableStatus.NONE:
    case ResolvableStatus.RUNNING: {
      return <Loader>Loading organization data...</Loader>;
    }
    case ResolvableStatus.FAILED: {
      return <ErrorState>Error loading organization data.</ErrorState>;
    }
    case ResolvableStatus.COMPLETED: {
      return (
        <OrganizationContext.Provider value={state.value}>
          {children}
        </OrganizationContext.Provider>
      );
    }
  }
};

const PlanLoader: Wrapper = ({ children }) => {
  const db = useContext(DatabaseContext);
  const plans = useContext(PlansContext);
  const [planSelectionState] = useContext(PlanSelectionContext);
  const { selectedBuilding, selectedFloor, selectedPlanId } =
    planSelectionState;
  const planMeta = useMemo(() => {
    const plan = plans.find(
      (p) => p.PLAN_ID === selectedPlanId
    ) as PlansListItem;
    return plan;
  }, [plans, selectedPlanId]);
  const [state, setState] = useState<Resolvable<PlanContextType>>({
    status: ResolvableStatus.NONE,
  });
  useEffect(() => {
    setState(updateRunning(null));
    const promisePlanData = new Promise<Plan>((resolve, reject) => {
      fetch(`${PUBLIC_URL}/plans_by_id/${selectedPlanId}/plan.json`).then(
        (response) => {
          response.json().then((planData) => {
            const imageUrl = `${PUBLIC_URL}/plans_by_id/${selectedPlanId}/${
              planData.image_url.split("?")[0].split("aws.com/")[1]
            }`;
            const plan: Plan = {
              ...planData,
              imageUrl,
              areaFeatureCollection: createFeatureCollection(
                planData.areas,
                planData
              ),
            };
            resolve(plan);
          }, reject);
        },
        reject
      );
    });

    const promiseSpaceQueries = new Promise<
      [Array<SpacesQueryRow>, AggregatedQueryResult]
    >((resolve, reject) => {
      db.connect().then(async (conn) => {
        await conn.query(`
          CREATE OR REPLACE VIEW agg_floor AS (
            SELECT *
            FROM agg_org
            WHERE BUILDING_NAME = '${selectedBuilding}'
            AND FLOOR_NAME = '${selectedFloor}'
          )
        `);

        const floorSpacesResult = parseSpacesQuery(
          await conn.query(`
          SELECT *
          FROM agg_floor
        `)
        );
        const floorAggregatedResult = parseAggregatedQuery(
          await conn.query(`
          SELECT
            COUNT(SPACE_ID) AS TOTAL_SPACES,
            SUM(AREA_SQFT) AS TOTAL_AREA_SQFT,
            SUM(TOTAL_HOURS) AS SUM_TOTAL_HOURS,
            SUM(USED_HOURS) AS SUM_USED_HOURS,
            SUM(UNUSED_HOURS) AS SUM_UNUSED_HOURS,
            AVG(AVG_OCCUPANCY_WHEN_USED) AS AVG_AVG_OCCUPANCY_WHEN_USED,
            AVG(AVG_TEAM_DENSITY_WHEN_USED) AS AVG_AVG_TEAM_DENSITY_WHEN_USED
          FROM agg_floor
        `)
        );

        await conn.close();
        resolve([floorSpacesResult, floorAggregatedResult]);
      }, reject);
    });

    Promise.all([promisePlanData, promiseSpaceQueries]).then(
      ([plan, [floorSpacesResult, floorAggregatedResult]]) => {
        setState(
          completeWith({
            plan,
            planMeta,
            floorSpacesResult,
            floorAggregatedResult,
          })
        );
      }
    );
  }, [selectedBuilding, selectedFloor, selectedPlanId, planMeta, db]);

  switch (state.status) {
    case ResolvableStatus.NONE:
    case ResolvableStatus.RUNNING: {
      return <Loader>Loading plan...</Loader>;
    }
    case ResolvableStatus.FAILED: {
      return <ErrorState>Error loading plan.</ErrorState>;
    }
    case ResolvableStatus.COMPLETED: {
      return (
        <PlanContext.Provider value={state.value}>
          {children}
        </PlanContext.Provider>
      );
    }
  }
};

import { Box, styled, Typography } from "@mui/material";

import { Wrapper } from "lib/types";

const ErrorPage = styled("div")({
  position: "fixed",
  width: "100vw",
  height: "100vh",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export const ErrorState: Wrapper = ({ children }) => {
  return (
    <ErrorPage>
      <Box sx={{ p: 2 }}>
        <Typography variant={"h3"}>
          Whoops, that's not quite right...
        </Typography>
        <Typography variant={"body1"}>
          You're seeing this page because there was an error. Please try
          reloading the page. If the problem persists, contact Density support.
        </Typography>
      </Box>
      <Box sx={{ p: 2 }}>{children}</Box>
    </ErrorPage>
  );
};

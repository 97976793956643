export const isNumber = (value: unknown): value is number => {
  return typeof value === "number" && !Number.isNaN(value);
};

export const formatAsInteger = (value: unknown) => {
  return isNumber(value)
    ? value.toLocaleString("en-US", {
        useGrouping: true,
        maximumFractionDigits: 0,
      })
    : "--";
};
export const formatAsDecimal = (value: unknown) => {
  return isNumber(value)
    ? value.toLocaleString("en-US", {
        useGrouping: true,
        maximumFractionDigits: 2,
      })
    : "--";
};
export const formatAsDollarsAndCents = (value: unknown) => {
  return isNumber(value)
    ? value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
    : "--";
};
export const formatAsDollars = (value: unknown) => {
  return isNumber(value)
    ? value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      })
    : "--";
};
export const formatAsPercent = (value: unknown) => {
  return isNumber(value)
    ? value.toLocaleString("en-US", {
        style: "percent",
      })
    : "--";
};
export const formatAsOccupancy = (value: unknown) => {
  return isNumber(value) ? `${formatAsDecimal(value)} ppl` : "--";
};
export const formatAsTeamDensity = (value: unknown) => {
  return isNumber(value) ? `${formatAsDecimal(value)} sqft / person` : "--";
};

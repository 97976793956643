import { FeatureCollection } from "geojson";

export type Nullable<T> = { [K in keyof T]: T[K] | null };

export type Wrapper<T = {}> = React.FunctionComponent<
  React.PropsWithChildren<T>
>;

export type PrototypeUser =
  | {
      mode: "superuser";
    }
  | {
      mode: "user";
      organizationName: string;
    }
  | {
      mode: "emre-special";
      organizationName: "AcmeCo";
    };

export type ActiveView = "map" | "chart";

export type SpacesQueryRow = {
  ORGANIZATION_NAME: string;
  BUILDING_NAME: string;
  FLOOR_NAME: string;
  SPACE_NAME: string;
  AREA_SQFT: number;
  FUNCTION: string;
  CAPACITY: number;
  COST_PER_SQFT: number;
  TOTAL_HOURS: number;
  USED_HOURS: number;
  UNUSED_HOURS: number;
  UNUSED_HOURS_PERCENT: number;
  PROJECTED_UNUSED_WORKING_HOURS_PER_YEAR: number;
  AVG_OCCUPANCY_WHEN_USED: number;
  AVG_TEAM_DENSITY_WHEN_USED: number;
  AREA_ID: string;
  SPACE_ID: string;
};

export type AggregatedQueryResult = {
  TOTAL_SPACES: number;
  TOTAL_AREA_SQFT: number;
  SUM_TOTAL_HOURS: number;
  SUM_USED_HOURS: number;
  SUM_UNUSED_HOURS: number;
  AVG_AVG_OCCUPANCY_WHEN_USED: number;
  AVG_AVG_TEAM_DENSITY_WHEN_USED: number;
};

export function isPolygon(area: Area): area is AreaPolygon {
  return area.shape === "polygon";
}

export type AreaPolygon = {
  id: string;
  name: string;
  shape: "polygon";
  space_id: string;
  polygon_verticies: Array<{
    x_from_origin_meters: number;
    y_from_origin_meters: number;
  }>;
};
export type AreaCircle = {
  id: string;
  name: string;
  shape: "circle";
  space_id: string;
  circle_centroid_x_meters: number;
  circle_centroid_y_meters: number;
  circle_radius_meters: number;
};
export type Area = AreaPolygon | AreaCircle;

export type Plan = {
  id: string;
  areas: Array<Area>;
  areaFeatureCollection: FeatureCollection;
  imageUrl: string;
  image_width_pixels: number;
  image_height_pixels: number;
  image_origin_x_pixels: number;
  image_origin_y_pixels: number;
  image_pixels_per_meter: number;
};

import { Reducer, useCallback, useReducer } from "react";
import { VisualizationScope } from "components/scope-toggle";

export type PlansListItem = {
  ORGANIZATION_NAME: string;
  BUILDING_NAME: string;
  FLOOR_NAME: string;
  COST_PER_SQFT: number;
  PLAN_ID: string;
};

export type PlanSelectionState = {
  orgs: Array<string>;
  buildings: Array<string>;
  floors: Array<string>;
  selectedOrg: string;
  selectedBuilding: string;
  selectedFloor: string;
  selectedPlanId: string;
  visualizationScope: VisualizationScope;
  colorBy: ColorByOption;
};

export type ColorByOption = "density" | "unused-hours";

export type PlanSelectionAction =
  | {
      type: "select-org";
      org: string;
    }
  | {
      type: "select-building";
      building: string;
    }
  | {
      type: "select-floor";
      floor: string;
    }
  | {
      type: "select-color-by";
      value: ColorByOption;
    }
  | {
      type: "select-visualization-scope";
      scope: VisualizationScope;
    };

export function usePlanSelection(plans: Array<PlansListItem>) {
  const planSelectionReducer = useCallback<
    Reducer<PlanSelectionState, PlanSelectionAction>
  >(
    (state, action) => {
      switch (action.type) {
        case "select-org": {
          const { visualizationScope, colorBy } = state;
          const { org: selectedOrg } = action;
          const orgs = Array.from(
            new Set(plans.map((p) => p.ORGANIZATION_NAME))
          );
          const buildings = Array.from(
            new Set(
              plans
                .filter((p) => p.ORGANIZATION_NAME === selectedOrg)
                .map((p) => p.BUILDING_NAME)
            )
          );
          const selectedBuilding = buildings[0];
          const floors = Array.from(
            new Set(
              plans
                .filter(
                  (p) =>
                    p.ORGANIZATION_NAME === selectedOrg &&
                    p.BUILDING_NAME === selectedBuilding
                )
                .map((p) => p.FLOOR_NAME)
            )
          );
          const selectedFloor = floors[0];
          const selectedPlanId = plans.find(
            (p) =>
              p.ORGANIZATION_NAME === selectedOrg &&
              p.BUILDING_NAME === selectedBuilding &&
              p.FLOOR_NAME === selectedFloor
          )?.PLAN_ID!;

          return {
            orgs,
            buildings,
            floors,
            selectedOrg,
            selectedBuilding,
            selectedFloor,
            selectedPlanId,
            visualizationScope,
            colorBy,
          };
        }
        case "select-building": {
          const { orgs, buildings, selectedOrg, visualizationScope, colorBy } =
            state;
          const { building: selectedBuilding } = action;

          const floors = Array.from(
            new Set(
              plans
                .filter(
                  (p) =>
                    p.ORGANIZATION_NAME === selectedOrg &&
                    p.BUILDING_NAME === selectedBuilding
                )
                .map((p) => p.FLOOR_NAME)
            )
          );
          const selectedFloor = floors[0];
          const selectedPlanId = plans.find(
            (p) =>
              p.ORGANIZATION_NAME === selectedOrg &&
              p.BUILDING_NAME === selectedBuilding &&
              p.FLOOR_NAME === selectedFloor
          )?.PLAN_ID!;

          return {
            orgs,
            buildings,
            floors,
            selectedOrg,
            selectedBuilding,
            selectedFloor,
            selectedPlanId,
            visualizationScope,
            colorBy,
          };
        }
        case "select-floor": {
          const {
            orgs,
            buildings,
            floors,
            selectedOrg,
            selectedBuilding,
            visualizationScope,
            colorBy,
          } = state;
          const { floor: selectedFloor } = action;
          const selectedPlanId = plans.find(
            (p) =>
              p.ORGANIZATION_NAME === selectedOrg &&
              p.BUILDING_NAME === selectedBuilding &&
              p.FLOOR_NAME === selectedFloor
          )?.PLAN_ID!;

          return {
            orgs,
            buildings,
            floors,
            selectedOrg,
            selectedBuilding,
            selectedFloor,
            selectedPlanId,
            visualizationScope,
            colorBy,
          };
        }
        case "select-color-by": {
          const { value: colorBy } = action;
          return {
            ...state,
            colorBy,
          };
        }
        case "select-visualization-scope": {
          const { scope: visualizationScope } = action;
          return {
            ...state,
            visualizationScope,
          };
        }
      }
    },
    [plans]
  );

  const getInitialState = useCallback<() => PlanSelectionState>(() => {
    const visualizationScope: VisualizationScope = "floor";
    const colorBy: ColorByOption = "density";

    const orgs = Array.from(new Set(plans.map((p) => p.ORGANIZATION_NAME)));
    const selectedOrg = orgs[0];
    const buildings = Array.from(
      new Set(
        plans
          .filter((p) => p.ORGANIZATION_NAME === selectedOrg)
          .map((p) => p.BUILDING_NAME)
      )
    );
    const selectedBuilding = buildings[0];
    const floors = Array.from(
      new Set(
        plans
          .filter(
            (p) =>
              p.ORGANIZATION_NAME === selectedOrg &&
              p.BUILDING_NAME === selectedBuilding
          )
          .map((p) => p.FLOOR_NAME)
      )
    );
    const selectedFloor = floors[0];
    const selectedPlanId = plans.find(
      (p) =>
        p.ORGANIZATION_NAME === selectedOrg &&
        p.BUILDING_NAME === selectedBuilding &&
        p.FLOOR_NAME === selectedFloor
    )?.PLAN_ID!;

    return {
      orgs,
      buildings,
      floors,
      selectedOrg,
      selectedBuilding,
      selectedFloor,
      selectedPlanId,
      visualizationScope,
      colorBy,
    };
  }, [plans]);

  const [state, dispatch] = useReducer(planSelectionReducer, getInitialState());

  return {
    state,
    dispatch,
  };
}

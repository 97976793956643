import {
  MouseEventHandler,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Breadcrumbs,
  Link,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import * as colors from "@density/proto-ui-colors";

import { PlanSelectionContext, UserContext } from "lib/contexts";
import { UserMenu } from "components/user-menu";

export const TopNavigation: React.FunctionComponent = () => {
  const [user] = useContext(UserContext);
  const isEmreSpecial = useMemo(() => {
    return user.mode === "emre-special";
  }, [user.mode]);
  const [planSelectionState, planSelectionDispatch] =
    useContext(PlanSelectionContext);
  const {
    orgs,
    buildings,
    floors,
    selectedOrg,
    selectedBuilding,
    selectedFloor,
  } = planSelectionState;
  const [orgMenuAnchor, setOrgMenuAnchor] = useState<HTMLElement | null>(null);
  const [buildingMenuAnchor, setBuildingMenuAnchor] =
    useState<HTMLElement | null>(null);
  const [floorMenuAnchor, setFloorMenuAnchor] = useState<HTMLElement | null>(
    null
  );
  const orgMenuOpen = Boolean(orgMenuAnchor);
  const buildingMenuOpen = Boolean(buildingMenuAnchor);
  const floorMenuOpen = Boolean(floorMenuAnchor);
  const handleClickOrgMenu = useCallback<MouseEventHandler<HTMLElement>>(
    (evt) => {
      setOrgMenuAnchor(evt.currentTarget);
    },
    []
  );
  const handleClickBuildingMenu = useCallback<MouseEventHandler<HTMLElement>>(
    (evt) => {
      setBuildingMenuAnchor(evt.currentTarget);
    },
    []
  );
  const handleClickFloorMenu = useCallback<MouseEventHandler<HTMLElement>>(
    (evt) => {
      setFloorMenuAnchor(evt.currentTarget);
    },
    []
  );

  const handleOrgMenuClose = useCallback(() => {
    setOrgMenuAnchor(null);
  }, []);
  const handleBuildingMenuClose = useCallback(() => {
    setBuildingMenuAnchor(null);
  }, []);
  const handleFloorMenuClose = useCallback(() => {
    setFloorMenuAnchor(null);
  }, []);
  const handleOrgMenuSelect = useCallback(
    (value: string) => {
      planSelectionDispatch({ type: "select-org", org: value });
      setOrgMenuAnchor(null);
    },
    [planSelectionDispatch]
  );
  const handleBuildingMenuSelect = useCallback(
    (value: string) => {
      planSelectionDispatch({ type: "select-building", building: value });
      setBuildingMenuAnchor(null);
    },
    [planSelectionDispatch]
  );
  const handleFloorMenuSelect = useCallback(
    (value: string) => {
      planSelectionDispatch({ type: "select-floor", floor: value });
      setFloorMenuAnchor(null);
    },
    [planSelectionDispatch]
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: 40,
        p: 1,
        px: 2,
        borderBottom: `1px solid ${colors.Gray100}`,
      }}
    >
      <Menu
        open={orgMenuOpen}
        onClose={handleOrgMenuClose}
        anchorEl={orgMenuAnchor}
      >
        <MenuList dense>
          {orgs.map((org) => {
            return (
              <MenuItem key={org} onClick={() => handleOrgMenuSelect(org)}>
                {isEmreSpecial ? "AcmeCo" : org}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
      <Menu
        open={buildingMenuOpen}
        onClose={handleBuildingMenuClose}
        anchorEl={buildingMenuAnchor}
      >
        <MenuList dense>
          {buildings.map((building, i) => {
            return (
              <MenuItem
                key={building}
                onClick={() => handleBuildingMenuSelect(building)}
              >
                {isEmreSpecial ? `Building ${i + 1}` : building}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
      <Menu
        open={floorMenuOpen}
        onClose={handleFloorMenuClose}
        anchorEl={floorMenuAnchor}
      >
        <MenuList dense>
          {floors.map((floor, i) => {
            return (
              <MenuItem
                key={floor}
                onClick={() => handleFloorMenuSelect(floor)}
              >
                {isEmreSpecial ? `Floor ${i + 1}` : floor}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
      <Box display={"flex"} flexGrow={1}>
        <Breadcrumbs>
          <Link onClick={handleClickOrgMenu} sx={{ cursor: "pointer" }}>
            {isEmreSpecial ? "AcmeCo" : selectedOrg}
          </Link>
          <Link onClick={handleClickBuildingMenu} sx={{ cursor: "pointer" }}>
            {isEmreSpecial ? "A Building" : selectedBuilding}
          </Link>
          <Link onClick={handleClickFloorMenu} sx={{ cursor: "pointer" }}>
            {isEmreSpecial ? "A Floor" : selectedFloor}
          </Link>
        </Breadcrumbs>
      </Box>

      <UserMenu />
    </Box>
  );
};
